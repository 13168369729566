import React from 'react';
import { graphql } from 'gatsby';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import { H2, H2Background, H3, H4 } from '../components/common/H.style';
import { UL } from '../components/common/UL.style';
import { LI } from '../components/common/LI.style';
import ButtonList from '../components/ButtonList';
import AppButton from '../components/AppButton';
import onlineHero from '../assets/img/telemed-top_bana.jpg';
import turnImage from '../assets/img/turn-image.jpg';
import appImg from '../assets/img/telemed-link_2.jpg';


const applicationLink = 'https://telemed-app.hirahata-clinic.or.jp/app_form.html?locate=shibuya';

const DentalArticleList = ({ list = [] }) => (
  <Flex column>
    {list.map(({ id, date, title, path }, index) => (
      <Flex
        key={id}
        css={`
          height: 60px;
          margin: 5px 0;
          border-bottom: ${(list.length - 1 !== index && 'solid 1px #ccc') || 'inherit'};
        `}
      >
        <Flex center>
          <span
            css={`
              font-size: 12px;
              color: #666666;
              margin-right: 30px;
            `}
          >
            {date}
          </span>
        </Flex>
        <Flex center>
          <Link to={path}>
            <span
              css={`
                font-size: 16px;
                color: #3b4043;
                overflow-wrap: anywhere;
              `}
            >
              {title}
            </span>
          </Link>
        </Flex>
      </Flex>
    ))}
  </Flex>
);

const Telemedicine = ({ data: { allMarkdownRemark, allFile } }) => {
  const { size } = useTrackedState();
  const dentalArticles = allMarkdownRemark.edges
    .filter(
      edge => !!edge.node.frontmatter.date
    )
    .map(({ node: { id, frontmatter: { title, path } } }) => ({
      id,
      title,
      path,
    }));
  return (
    <SubLayout topTitle="オンライン診療のながれ">
      <Img src={onlineHero} />
      
      <H3>1. お申し込み</H3>
      <P>
        こちらの申し込みフォームより必要事項を入力してお申し込みください。
      </P>
      <div css={`
              width:300px;
              margin:0 auto;
              text-align:center;
      `}>
      <AppButton
         size={size}
      />
      </div>
      
      <H3>2. お申し込み後〜診察を受ける前</H3>
      <Flex collum>
      <P>
        お申し込み後、当院(info@hirahata-clinic.or.jp)より受付完了メールを送らせていただきます。<br />
        当院のオンライン診療は外来診療とも合わせた順番制です。<br />
        お申し込みを受理させていただいた時点で当院チェックイン扱いとなります。<br />
        診察の順番となりましたら、当院医師より電話(※)をさせていただきます。<br />
        タイミングが合わずにお電話を取ることができなかった場合は、時間をおいて当院より再度電話をさせていただきます。<br />
        <div css={`
              max-width:600px;
              margin:2em auto;
              text-align:center;
        `}>
        <Img src={turnImage} css={'max-width:680px;'}/>
        </div>
        
        （※）初診の方や、顔色・舌の状態を見させていただきたい場合はビデオ通話診察をさせていただきます。
        
        <H4>待ち時間について</H4>
        <P>上記の通り、当院は外来診療と並行してオンライン診療を行います。<br />
        そのため「受付後どのくらいの時間で電話ができるか」をお知らせすることができません。<br />
        大変申し訳ございませんが、その点のご了承をお願いいたします。<br />
        <UL>
          <LI><b>院長指定の場合</b>　・・・5分〜1時間程度</LI>
          <LI><b>院長以外の医師の場合</b>　・・・5〜20分程度</LI>
        </UL>
        タイミングが合わずにお電話を取ることができなかった場合は、時間をおいて当院より再度電話をさせていただきます。
        </P>
      </P>
      </Flex>
      <H3>3. 診察</H3>
      <P>
        基本は電話にて診療を行います。<br />
        初診の方や、顔色・舌の状態を見させていただきたい場合はビデオ通話診察をさせていただきます。
      </P>
      
      <H3>4. 診察終了〜お会計</H3>
      <P>
        診察終了後、会計処理したのち請求メールを送らせていただきます。<br />
        メール内のリンクをタップ（クリック）してクレジットカード情報を入力してください。<br /><br />

        当院スタッフが支払情報をシステム上でチェックさせていただいた後、処方箋を薬局にFAX＆郵送いたします。
      </P>
      
      <H3>5. 薬局へ薬を取りに行く</H3>
      <P>
        ご指定の薬局の営業時間内に薬を取りに行ってください。<br />
        <b>薬代は別途かかります。</b>
      </P>
      
      <div css={`
          max-width:500px;
          margin:5em auto 3em;
          text-align:center;
      `}>
      <a href={applicationLink}>
        <Img src={appImg} css={'margin-bottom:2em;'}/>
        お申し込みはこちら
      </a>
      </div>
      
     < /SubLayout>
  );
};

export default Telemedicine;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/dental-articles/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
  }
`;
