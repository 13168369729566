import React from 'react';

import IconButton from './IconButton';

const AppButton = ({ link = 'https://telemed-app.hirahata-clinic.or.jp/app_form.html?locate=shibuya', width }) => (
  <IconButton
    width={300}
    link={link}
    external
    label="お申し込みフォーム"
    buttonColor="#00245f"
  />
);

export default AppButton;